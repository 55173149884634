<template>
  <div class="wrapper">
   
    <router-view />

  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>
<style scoped>
 body {
    overflow: hidden !important; /* Hide overflow on the body */
  }
</style>