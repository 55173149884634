/* eslint-disable */
import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      auth: 0
    },
    component: () => import('../views/LoginForm.vue'),
  },
  {
    path: '/MyToolTip',
    name: 'MyToolTip',
    meta: {
      auth: 0
    },
    component: () => import('../views/MyToolTip.vue'),
  },
  {
    path: '/home',
    component: () => import('../layouts/layoutauthenticated.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          auth: 1
        },
        component: HomeView

      },
      {
        path: '/ModBuildingClass',
        name: 'ModBuildingClass',
        component: () => import('../components/ModBuildingClass.vue')
      },
      {
        path: '/BuildingList',
        name: 'BuildingList',
        meta: {
          auth: 1
        },
        component: () => import('../views/BuildingList.vue')
      },
      {
        path: '/BuildingClass',
        name: 'BuildingClass',
        meta: {
          auth: 1
        },
        component: () => import('../views/BuildingClass.vue')
      },
      {
        path: '/BuildingOpe&Main',
        name: 'BuildingOpe&Main',
        meta: {
          auth: 1
        },
        component: () => import('../views/BuildingOpe&Main.vue')
      },
      {
        path: '/BuildingDetails/:id',
        name: 'BuildingDetails',
        meta: {
          auth: 1
        },
        component: () => import('../views/BuildingDetails.vue')
      },
      {
        path: '/BuildingGallery/:id',
        name: 'BuildingGallery',
        meta: {
          auth: 1
        },
        component: () => import('../views/BuildingGallery.vue')
        
      },
      {
        path: '/BuildingGallery1/:id',
        name: 'BuildingGallery1',
        component: () => import('../views/BuildingGallery1.vue')
      },
      {
        path: '/Dashboard',
        name: 'Dashboard',
        meta: {
          auth: 1
        },
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: '/MeCost',
        name: 'MeCost',
        meta: {
          auth: 1
        },
        component: () => import('../views/MeCost.vue')
      },
      {
        path: '/OpCost',
        name: 'OpCost',
        meta: {
          auth: 1
        },
        component: () => import('../views/OpCost.vue')
      },
      {
        path: '/MeCostIndi/:id',
        name: 'MeCostIndi',
        meta: {
          auth: 1
        },
        component: () => import('../views/MeCostIndi.vue')
      },
      {
        path: '/OpCostIndi/:id',
        name: 'OpCostIndi',
        meta: {
          auth: 1
        },
        component: () => import('../views/OpCostIndi.vue')
      }
      
    ]
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

let checkAuthentication = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.VUE_APP_API + 'auth/me',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('xxx')
      }
    }).then((resp) => {
      resolve(resp)
    }).catch((err) => {
        reject(err)
    })
  })
}
router.beforeEach((to, from, next) => {

  if(localStorage.getItem('xxx')){

    checkAuthentication()
    .then((resp) => {
      if(to.meta.auth == 1){
        next()
      }else{
        next({ name: 'Dashboard' })
      }
    }).catch((err) => {
      localStorage.removeItem('xxx')
      next({ name: 'Login' });
    })

  }else{
    if( to.name == 'Login'){
      next()
    }else{
       next({ name: 'Login' });
    }
  }

  //next()
  
})

export default router
